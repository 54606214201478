import React from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import Layout from '../components/layout';
import SEO from '../components/seo';

const ResearchBasisPage = () => {
  return (
    <Layout>
      <SEO title="Research Basis" />
      <AboutContainer>
        <h2 className="page-title">Research Basis</h2>

        <section className="content">
          <div className="long-text">
            <p>
              An emerging research literature establishes that police unions,
              and the police union contracts and laws they&rsquo;ve worked to
              enact, are associated with a range of problematic policing
              outcomes. A{' '}
              <a href="https://www.tandfonline.com/doi/abs/10.1080/15614260802081253?journalCode=gppr20">
                review
              </a>{' '}
              of the available research literature on police unions by the
              Journal of Police Practice and Research found &ldquo;virtually all
              of the published items that express an opinion on the impact of
              police unions regard them as having a negative effect,
              particularly on innovation, accountability, and police &mdash;
              community relations.&rdquo; In one of the first studies examining
              this issue, Professor Samuel Walker and Kevin Keenan{' '}
              <a href="https://samuelwalker.net/wp-content/uploads/2010/06/POBillsofRights.pdf">
                documented
              </a>{' '}
              in 2005 how police unions helped to enact statewide police bill of
              rights laws with provisions that constitute &ldquo;impediments to
              police accountability&rdquo; such as the establishment of formal
              waiting periods that delay investigations; prohibitions on the use
              of [civilian] investigators in misconduct investigations; statutes
              of limitations on the retention and use of data on officer
              misconduct; the failure to allow for reasonable exceptions to
              provisions regulating the time, place, and manner of investigative
              interviews; excessive limitations on how many officers can
              participate, how many can speak at one time, and the use of
              &ldquo;foul&rdquo; language; and requiring the disclosure of the
              names of complainant(s) in every case, among other issues.&nbsp;
            </p>
            <p>
              Subsequent research has examined the ways in which police union
              contracts, in addition to these state laws, contain similar
              problematic provisions protecting officers accused of misconduct.
              For example, an{' '}
              <a href="https://samuelwalker.net/wp-content/uploads/2016/10/BALTIMORE-POLICE-UNION-CONTRACTFinal.pdf">
                analysis
              </a>{' '}
              of Baltimore&rsquo;s Police Union Contract found the contract
              contained provisions that worked in concert with the state police
              bill of rights law to &ldquo;impede the effective investigation of
              alleged officer misconduct and shield officers from
              discipline.&rdquo; In addition to their impact on police
              accountability, researchers have also found that some of these
              provisions - most notably provisions delaying interrogations of
              officers - are{' '}
              <a href="https://samuelwalker.net/wp-content/uploads/2015/06/48HourSciencepdf.pdf">
                based
              </a>{' '}
              on faulty science. In 2015, Campaign Zero worked with researchers
              and legal experts to review and{' '}
              <a href="http://checkthepolice.org">publish</a> for the first time
              the police union contracts for 81 of the 100 largest US cities as
              well as police bill of rights laws in 15 states - finding six ways
              in which these contracts and laws could make it more difficult to
              hold officers accountable for misconduct. In 2016, University of
              Chicago&#39;s Aziz Huq and Richard McAdam&rsquo;s{' '}
              <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2712967">
                argue{' '}
              </a>
              that &ldquo;Delay Privileges&rdquo; and &ldquo;Interrogation
              Buffers&rdquo; found in bargaining agreements, state laws, and
              municipal codes provide officers with heightened procedural
              protections which have the &ldquo;predictable effect to obstruct
              investigations.&rdquo; &nbsp; Building off of the Campaign Zero
              framework, Professor Stephen Rushin{' '}
              <a href="https://scholarship.law.duke.edu/dlj/vol66/iss6/1/">
                published
              </a>{' '}
              a review of 178 police union contracts in the Duke Law Review in
              2017, finding &ldquo;a substantial number of these agreements
              limit officer interrogations after alleged misconduct, mandate the
              destruction of disciplinary records, ban civilian oversight,
              prevent anonymous civilian complaints, indemnify officers in the
              event of civil suits, and limit the length of internal
              investigations.&rdquo; Reuters conducted a similar{' '}
              <a href="https://www.reuters.com/investigates/special-report/usa-police-unions/">
                analysis
              </a>{' '}
              of police union contracts in 82 cities, finding &ldquo;cities have
              bargained away the power to discipline police officers, often in
              closed negotiation meetings with local unions&rdquo; and
              identifying broad categories of contract protections for officers
              such as provisions that disqualify complaints of misconduct, erase
              disciplinary records, give officers preferential access to
              evidence, and allow officers to substitute vacation or
              discretionary time for suspension. Finally, a study{' '}
              <a href="https://scholarship.law.upenn.edu/cgi/viewcontent.cgi?article=9652&amp;context=penn_law_review">
                published
              </a>{' '}
              in the University of Pennsylvania Law Review examined police union
              contracts in over 600 cities and found that, under the
              disciplinary appeals processes established by these contracts,
              &ldquo;police departments must often rehire or significantly
              reduce disciplinary sanctions against officers who have engaged in
              serious misconduct.&rdquo;&nbsp;
            </p>
            <p>
              Researchers have also begun to examine the relationship between
              the problematic provisions in police union contracts and police
              bill of rights laws and a range of negative policing outcomes. For
              example, a Washington Post{' '}
              <a href="https://www.washingtonpost.com/graphics/2017/investigations/police-fired-rehired/">
                analysis
              </a>{' '}
              of 1,881 officers fired by the nation&rsquo;s largest police
              departments from 2006-2017 found that &ldquo;departments have been
              forced to reinstate more than 450 officers after appeals required
              by police union contracts.&rdquo; A 2018 study by Professors
              Dharmapala, McAdams and Rappaport{' '}
              <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3095217">
                found
              </a>{' '}
              evidence that &ldquo;collective bargaining rights led to a
              substantial increase in violent incidents of misconduct among
              sheriff&rsquo;s offices...the effect of collective bargaining
              rights is concentrated among sheriff&rsquo;s offices that
              subsequently adopted collective bargaining agreements, and the
              timing of the adoption of these agreements is associated with
              increases in violent misconduct.&rdquo; A 2018{' '}
              <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3246419">
                study
              </a>{' '}
              from Oxford researcher Abdul Rad found that places with the types
              of problematic clauses in their police union contracts and police
              bill of rights laws that Campaign Zero identified were also
              associated with higher rates of police abuse, though it cautions
              that it&rsquo;s difficult to prove the relationship is causal.
              Finally, in a forthcoming study from economists at the University
              of Victoria, researchers{' '}
              <a href="https://www.npr.org/transcripts/869176943">found</a> that
              after officers gained access to collective bargaining rights that
              there was a substantial increase in killings of civilians -
              comprising an estimated 60 to 70 additional people killed by
              police per year, the overwhelming majority of whom were people of
              color.
            </p>
            <p>
              Researchers have also begun to document the ways in which police
              unions have influenced legislators to enact these laws and
              contracts - and to block police accountability legislation. A
              Campaign Zero{' '}
              <a href="https://www.checkthepolice.org/california">analysis</a>{' '}
              of police union lobbying and political contributions in California
              found that police unions had contributed to 118 of
              California&rsquo;s 120 state legislators from 2011-2017 and spent
              over $2 million in lobbying the state legislature during a
              one-year period - substantially more than had been spent by racial
              justice organizations within the state or even the NRA. A
              nationwide{' '}
              <a href="http://nomorecopmoney.com/cop-money.html">analysis</a>{' '}
              conducted by NoMoreCopMoney, using data obtained from the National
              Institute on Money in State Politics, found that the political
              action committees of police unions and associations had donated
              over $19,600,000 to 3,530 state and local politicians since 2015.
              Both analyses found that police union contributions
              disproportionately went to Democratic politicians, with Campaign
              Zero&rsquo;s analysis also finding that police unions contributed
              most to Democrats who opposed police accountability legislation.
              Another{' '}
              <a href="https://law.stanford.edu/wp-content/uploads/2017/05/SLPR-Vol.-28-1-Bies.pdf">
                study{' '}
              </a>
              found how police unions work to block &ldquo;sunshine laws&rdquo;
              that would make records of police misconduct available to the
              public. Professors Catherine Fisk and L Song Richardson{' '}
              <a href="http://www.gwlr.org/wp-content/uploads/2017/07/85-Geo.-Wash.-L.-Rev.-712.pdf">
                identify{' '}
              </a>
              a number of additional ways in which police unions block systemic
              changes to policing from being implemented, including how police
              unions utilize state laws to force cities to negotiate over
              changes to police disciplinary processes, use of force reporting
              and investigation procedures, the size and scope of the police
              force, and other changes deemed related to &ldquo;conditions of
              employment.&rdquo; For example, a{' '}
              <a href="http://inthesetimes.com/features/police-killings-union-contracts.html">
                review
              </a>{' '}
              of 17 cities where the US Department of Justice mandated police
              reforms found at least 7 cities (41%) had reforms blocked due to
              their police union contracts..&nbsp;
            </p>
            <p>
              Altogether, the existing body of research shows how police unions
              wield considerable influence over the politics and policy
              concerning police accountability - and that they have used this
              influence to put in place a special set of legal and
              administrative protections that are associated with higher rates
              of police misconduct and lower rates of police accountability. The
              NixThe6 campaign builds from this foundational research and
              translates it into a call to action for communities and lawmakers
              to dismantle these barriers to holding police accountable and, in
              doing so, make communities safer.
            </p>
          </div>
        </section>
      </AboutContainer>
    </Layout>
  );
};

export const AboutContainer = styled.div`
  .page-title {
    text-align: center;
    margin-bottom: 1em;

    @media (max-width: 767.98px) {
      font-size: 30px;
      line-height: 1.375;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.5em;
  }

  .content {
    max-width: 1184px;
    margin: 0 auto;
    padding: 80px 104px;
    background: #fff;
    color: ${constants.contentTextColor};
    border-radius: 8px;

    @media (max-width: 767.98px) {
      padding: 40px 1rem;
      border-radius: 0;
    }

    .long-text {
      max-width: 640px;
      margin: 0 auto;
    }
  }
`;

export default ResearchBasisPage;
